@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Thin.woff2') format('woff2');
}
@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Light.woff2') format('woff2');
}
@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Regular.woff2') format('woff2');
}
@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Medium.woff2') format('woff2');
}
@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Bold.woff2') format('woff2');
}
@font-face {
  font-family: qdon-font-sans-serif;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('~fonts/noto-sans-kr/NotoSansKR-Black.woff2') format('woff2');
}
