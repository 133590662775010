// Colourise mention
a.status-link {
  color: $qdon-mention-color;
}

// Fix menu color on single column;
.column-link--transparent {
  color: rgba($primary-text-color, 0.9);
}

html {
  // scrollbar-color: $ui-secondary-color rgba($ui-base-color, 0.1);
  scrollbar-width: thin;
}

.status__content__text,
.e-content,
.edit-indicator__content,
.reply-indicator__content {
  pre:has(> code) {
    border: 1px solid $ui-base-color;
  }
}
